import ApiService from "../../../api/api.service";
import JwtService from "../../../common/jwt.service"
import Vue from 'vue';

const state = {
    token: null,
    errors: {
        login: [],
        register: []
    },
    nom:null,
    prenom:null,
    email:null,
    gmail:null,
    photo:null,
    userId: null,
    isAuthenticated: false,
};

const getters = {
    getLoginErrors(state) {
        return state.errors.login;
    },
    getRegistrationErrors(state) {
        return state.errors.register;
    },
    getErrors(state){
        return state.errors;
    },
    currentUser(state){
        return state.userId;
    },
    currentNom(state){
        return state.nom;
    },
    currentPrenom(state){
        return state.prenom;
    },
    currentImgNego(state){
        return state.photo;
    },
    currentGmail(state){
        return state.gmail;
    },
    currentAgence(state){
        return state.agence_principale;
    },
    currentRang(state){
        return state.rang;
    },

    isAuthenticated(state){
        return state.isAuthenticated;
    }
};

const mutations = {
    clearErrors(state) {
        state.errors = [];
    },
    setError(state, {target, errors}) {

        Vue.set(state.errors, target, []);

        for (let key in errors)
            state.errors[target].push(errors[key][0]);
    },
    setData(state, datas) {
        state.prenom = datas.prenom;
        state.nom = datas.nom;
        state.photo = datas.photo;
        state.gmail = datas.gmail
        state.agence_principale = datas.agence_principale
        state.rang = parseInt(datas.rang)
    },
    setUser(state, data) {
        state.isAuthenticated = true;
        state.token = data.token;
        state.errors = {
            login: [],
            register: []
        };
        JwtService.setToken(data.token);
    },
    logout(state) {
        state.isAuthenticated = false;
        state.token = null;
        JwtService.unsetToken();
    },
};

const actions = {
    user(context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("api", "user", credentials)
                .then(({data}) => {
                    context.commit(
                        "setData", {
                            prenom: data.ut_prenom,
                            nom: data.ut_nom,
                            photo:data.ut_photo,
                            gmail:data.gmail_account,
                            agence_principale:data.ut_agence,
                            rang:data.ut_rang
                        }
                    );
                    resolve(data);
                })
                .catch(({response}) => {
                    /*context.commit(
                        "setError",
                        {target: 'login', message: response.data.error}
                    );*/
                    reject(response);
                });
        });
    },
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("oauth/token", credentials)
                .then(({data}) => {
                    context.commit("clearErrors");
                    context.commit(
                        "setUser", {token: data.access_token}
                    );
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        "setError",
                        {target: 'login', message: response.data.error}
                    );
                    reject(response);
                });
        });
    },
    logout(context) {
        //context.commit("logout");
        //context.commit("resetState", null, { root: true });
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("api","user/logout")
                .then(({data}) => {
                    context.commit("logout");
                    context.commit("resetState", null, { root: true });
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        "setError",
                        {target: 'logout', message: response.data.error}
                    );
                    reject(response);
                });
        });
    },
    checkAuth(context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("api","authenticated")
                .catch(({ response }) => {
                    response;
                    context.commit("logout");
                });
        } else {
            context.commit("logout");
        }
    },
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state
}
