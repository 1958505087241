<template>
  <v-container fluid>
    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        top
    >
      {{ snack_text }}

    </v-snackbar>
      <v-tabs
          v-model="tab"


      >
        <v-tab
            :key="TAB1"
        >
          Informations générales
        </v-tab>
        <v-tab
            :key="TAB2"
        >
          Informations administrative
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            :key="TAB1"
        >
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
              <v-text-field
                  outlined
                  label="Identifiant"
                  placeholder="Saississez votre identifiant"
                  :value="identifiant"
                  hide-details="auto"
                  prepend-inner-icon="fas fa-user"
              ></v-text-field>
                  <v-select
                      outlined
                      :items="rangs"
                      label="Rang"
                      class="mt-4"
                  ></v-select>
              <v-text-field
                  outlined
                  label="Nom"
                  :value="nom"
                  hide-details="auto"
                  :data-id="profil_id"
                  data-name="ut_nom"
                  @blur="autosave"
              ></v-text-field>
              <v-text-field
                  outlined
                  label="Prénom"
                  :value="prenom"
                  hide-details="auto"
                  class="mt-4"
                  :data-id="profil_id"
                  data-name="ut_prenom"
                  @blur="autosave"
              ></v-text-field>

                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          outlined
                          v-model="date"
                          label="Date de naissance"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        ref="picker"
                        v-model="date"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                  </v-menu>

              <v-text-field
                  outlined
                  label="Téléphone"
                  :value="telephone"
                  hide-details="auto"
                  :data-id="profil_id"
                  data-name="ut_telephone"
                  prepend-inner-icon="fas fa-phone"
                  @blur="autosave"
              ></v-text-field>
                  <v-text-field
                      outlined
                      class="mt-4"
                      label="Adresse électronique"
                      :value="email"
                      :data-id="profil_id"
                      data-name="ut_adresseMail"
                      prepend-inner-icon="fas fa-envelope"
                      @blur="autosave"
                  ></v-text-field>
                  <v-text-field
                      outlined
                      label="Adresse Gmail"
                      :value="gmail"
                      :data-id="profil_id"
                      data-name="gmail_account"
                      prepend-inner-icon="fab fa-google"
                      @blur="autosave"
                  ></v-text-field>

                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Présentation"
                      v-model="presentation"
                      :data-id="profil_id"
                      data-name="ut_presentation"
                      @blur="autosave"
                  ></v-textarea>

                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Honoraires"
                      v-model="honoraires"
                      :data-id="profil_id"
                      data-name="ut_infos"
                      @blur="autosave"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
            :key="TAB2"
        >
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
              <v-text-field
                  outlined
                  :value="rcs_rsac"
                  label="RCS / RSAC"
                  hide-details="auto"

              >

              </v-text-field>
              <v-text-field
                  outlined
                  label="SIRET"
                  :value="siret"
                  hide-details="auto"
                  class="mt-4"
              >

              </v-text-field>
                  <v-select
                      outlined
                      :items="formes_juridiques"
                      label="Forme juridique"
                      class="mt-4"
                  ></v-select>
                  <v-text-field
                      outlined
                      :value="nom_compagnie_assurance"
                      label="Nom compagnie d'assurance"
                      hide-details="auto"

                  >
                  </v-text-field>
                  <v-text-field
                      outlined
                      :value="numero_rcp"
                      label="Numéro de contract RCP"
                      hide-details="auto"
                      class="mt-4"
                  >

                  </v-text-field>

                  <v-menu
                      ref="menu-rcp"
                      v-model="menu_rcp"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          outlined
                          v-model="date_rcp"
                          label="Date de la RCP"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        ref="picker"
                        v-model="date_rcp"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                      outlined
                      label="Comission"
                      :value="commission"
                      hide-details="auto"
                      class="mt-4"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
  </v-container>
</template>

<script>
import ApiService from "@/api/api.service";

export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      snackbar:false,
      snack_text:null,
      profil_id:null,
      formes_juridiques: ["", "Autoentreprise","SARL"],
      rangs: ["", "Désactivé","Négociateur","Chef d'agence","Chef d'enseigne"],
      tab: null,
      date: null,
      date_rcp: null,
      menu: false,
      menu_rcp: false,
      identifiant:null,
      nom:null,
      prenom:null,
      date_de_naissance:null,
      telephone:null,
      email:null,
      gmail:null,
      presentation:null,
      honoraires:null,
      siret:null,
      rcs_rsac:null,
      nom_compagnie_assurance:null,
      numero_rcp:null,
      commission:null
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    autosave (event) {
      this.snackbar = true
      this.snack_text = "Sauvegarde en cours";
      const formData = new FormData();
      formData.append('data[0][name]', event.target.getAttribute('data-name'));
      formData.append('data[0][id]', event.target.getAttribute('data-id'));
      formData.append('data[0][value]', event.target.value);
      ApiService.setHeader();
      ApiService.post("api/autosave", formData)
          .then(({data}) => {
            this.snackbar = false
            console.log(data);
          })
      console.log(event.target.getAttribute('data-id'))
    }
  },
  mounted () {
    var current_id = this.id!='' ? '/'+this.id : ""
    ApiService.setHeader();
    ApiService.get("api", "user"+current_id)
        .then(({data}) => {
          this.profil_id = data.ut_id
           this.identifiant = data.ut_login
           this.prenom = data.ut_prenom
           this.nom = data.ut_nom
          this.telephone = data.ut_telephone
          this.email = data.ut_adresseMail
          this.gmail = data.gmail_account
          this.presentation = data.ut_presentation
          this.honoraires = data.ut_infos
          this.siret = data.ut_SIRET
          this.rcs_rsac = data.ut_RCS
          this.nom_compagnie_assurance = data.ut_nomCompagnieAssurance
          this.numero_rcp = data.ut_numeroContratRCP
          this.commission = data.ut_commission
        })
        .catch(({response}) => {
          response
          /*context.commit(
              "setError",
              {target: 'login', message: response.data.error}
          );*/

        });

  }
}
</script>