import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'


import UserProfile from "@/components/UserProfile";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Drive Fox 5',
    component: () =>
        import(
            /* webpackChunkName: "dashboard" */ "@/views/layouts/Dashboard.vue"
        ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/home',
        name: 'Homepage',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
      path: '/liste-biens',
      name: 'Liste des biens',
        meta: {
          requiresAuth: true
        },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dashboard" */ '../views/ListeBiens.vue')
      },
      {
        path: '/liste-recherches',
        name: 'Liste des recherches',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ListeRecherches.vue')
      },
      {
        path: '/liste-contacts',
        name: 'Liste des contacts',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ListeContacts.vue')
      },
      {
        path: '/annonce',
        name: 'Mon annonce',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditAnnonce.vue')
      },
      {
        path: '/enseigne',
        name: 'Mon enseigne',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/MyBrand.vue')
      },
      {
        path: '/envoyer-mail',
        name: 'Envoyer un mail',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/SendMail.vue')
      },
      {
        path: '/agenda',
        name: 'Agenda',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Agenda.vue')
      },
      {
        path: '/edit-my-profile',
        name: 'Edtier mon profil',
        meta: {
          requiresAuth: true,
        },
        component: UserProfile,
      },
      {
        path: '/edit-profile/:id',
        name: 'Editer un profil',
        meta: {
          requiresAuth: true,
        },
        component: UserProfile,
        props:true
      },
      {
        path: '/my-profile',
        name: 'Profil utilisateur',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/ProfileCard.vue')
      },
      {
        path: '/mon-agence',
        name: 'Mon agence',
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/AgencyCard.vue')
      },
      {
        path: '/logout',
        name: 'Logout',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Logout.vue')

      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
        import(
            /* webpackChunkName: "dashboard" */ "@/views/layouts/FullWidth.vue"
            ),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //if (to.name !== 'Login' && Promise.all([store.dispatch("auth/checkAuth")])) next({ name: 'Login' })
  //else next()
  /*Promise.all([store.dispatch("auth/checkAuth")]).then(next)*/
  //next

  document.title = to.name;
  Promise.all([store.dispatch("auth/checkAuth")]).then(next)

  if(to.meta.requiresAuth && !store.getters['auth/isAuthenticated']){
    next('/login');
  } else {
    next();
  }
  //alert(store.getters["auth/currentUser"])


/*next;
  Promise.all([store.dispatch("auth/checkAuth")]).then(values => {
    alert('ok')
    console.log(values);
  }, reason => {
    console.log(reason)
  });*/
})

export default router
